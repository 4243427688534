body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Bootstrap overrides */

input.form-control,
input.form-control:focus,
textarea.form-control,
textarea.form-control:focus,
select.form-control,
select.form-control:focus,
.rbt-input-multi.form-control,
.rbt-input-main {
  background: #0c0c0c;
  color: white;
}
input.form-control:disabled {
  background: #4c4c4c;
  cursor: not-allowed;
  color: #a3a3a3;
}
/* Stupid hack to make the inputs look right when autofilled */
input.form-control:-webkit-autofill {
  box-shadow: 0 0 0px 1000px black inset;
  -webkit-text-fill-color: white;
}
.rbt-token {
  background-color: #3e3e3e;
  color: white;
}
